/* eslint react/prop-types: 0 */
import React from "react";


export default function Project({title, fullTitle, number, type, aims, activ, partners}) {
    return(
        <div id = {title} name = {title}>
            <div className = "text-red-dark font-lora font-bold text-2xl">{fullTitle !== "" ? title + " | " + fullTitle : title}</div>
            <div className = "text-red-dark font-lora text-xl">{type + " | " + number}</div>
            <div className = "font-lora">
                <br/>
                {partners && partners.length > 0 && (
                    <div>
                        <div className = "font-bold text-lg">Partners</div>
                        <div>{partners.join(`, `)}</div>
                    </div>
                )}
                <br/>
                {aims && aims.length > 0 && (
                    <div>
                        <div className = "font-bold text-lg">Aims</div>
                        <ul className = "list-inside list-disc ml-4">
                            {aims.map((aim) => (
                                <li key = {aim}>
                                    {aim}
                                </li>
                            ))}
                        </ul>
                        <br/>
                    </div>
                )}
                {title === "valUE" && (
                    <div>
                        <p>
                        The project activities, called Solidarity Labs, engage citizens in a gaming exercise that allows them to understand the impact of solidarity in the community as well as across the Member States in crisis scenarios. 
                        The scenarios are related to what each of the communities involved experienced during the pandemic and the lockdown: material and immaterial poverty, from food aid to children’s educational support services, access to rights.
                        </p>
                    </div>
                )} 
                {title === "FOREST" && (
                    <div>
                        <div className="text-lg font-bold text-red-dark"> 
                            <a href = "https://drive.google.com/file/d/16yk-eIRxhrKxvEhk8_vUELDvpiPySkfV/view?usp=sharing" target="_blank" rel="noopener noreferrer">Download the leaflet here</a>
                        </div>
                        <br/>
                        <p>
                        FOREST replies to the <b>fragmentation of competences in forestry management</b> at national and international levels, 
                        by giving rise <b>to debate around the possibility to create a standard level of competences and skills</b> of forestry workers and <b>promoting innovation and professionalism</b> 
                        by joint actions and initiatives.
                        </p>
                        <br/>
                        <div className = "font-bold text-lg">Main Objective</div>
                        <p>
                        exchange of good practice on the countries strategies in the forest sector
                        </p>
                        <br/>
                        <div className = "font-bold text-lg">Priorities and topics</div>
                        <ul className = "list-inside list-disc ml-4">
                        <li>transparency and recognition of skills and qualifications</li>
                        <li>internalization strategies for VET providers harmonize training systems</li>
                        <li>the possibility to create standard level of competences and skills for forestry workers</li>
                        <li>define a common strategy to harmonize training systems</li>
                        </ul>
                        <br/>
                        <div className = "font-bold text-lg">Activities</div>
                        <p><b>Local activities</b> to involve local key actors in the European debate:</p>
                        <ul className = "list-inside list-disc ml-4">
                        <li>collecting information about existent certifications accepted and analysis of the state of the art in their regions/countries;</li>
                        <li>defining with local stakeholders an integration procedure of the common technical and legislative harmonisation strategy at local level</li>
                        </ul>
                        <p><b>6 Transnational meetings</b> aimed to define a common strategy to harmonize training systems and recognition of competences, under <b>4 focus themes</b>:</p>
                        <ul className = "list-inside list-decimal ml-4">
                        <li>safety in the workplace at EU level</li>
                        <li>legislative process: Comparison on the existence of skill certificates</li>
                        <li>sustainability and Innovation</li>
                        <li>focus on women inclusion on forestry sector</li>
                        </ul>
                    </div>
                )}
                {title === "SMART Generation" && (
                    <div>
                        <div className = "font-bold text-lg">Goals</div>
                        <ul className = "list-inside list-disc ml-4">
                        <li>to develop an innovative training model at European level in order to promote a conscious use of the smart phone</li>
                        <li>its potential and its risks</li>
                        <li>to open a public debate on the role of digital technologies in the process of the quality of social relationships between young people and between generations</li>
                        </ul>
                        <br/>
                    </div>
                )}
                {title === "WO.M.E.N" && (
                    <div>
                      <div className = "font-bold text-lg">Aim</div>
                      <p>
                        To strengthen and promote participation of women in politics, from the local to the EU level
                      </p>
                      <br/>
                    </div>
                )}
                {title === "RECOV" && (
                    <div>
                        <div className = "font-bold text-lg">Aim</div>
                        <p>
                        To create a network of towns eager to work together on exchanging common problems. It
                        deals with the problem of lacking communication and dialogue between governments and
                        citizens fuelling mistrust and passiveness of civil society.
                        </p>
                        <br/>
                        <div className = "font-bold text-lg">General Objective</div>
                        <p>
                        Creating local networks capable of working together on the improvement of local governance
                        through dialogue and participation, contributing to more inclusive and democratic society.
                        </p>
                        <br/>
                        <div className = "font-bold text-lg">Specific Objectives</div>
                        <ul className = "list-inside list-disc ml-4">
                            <li>to foster the exchange of best practices of local cooperation as well as to share solutions contributing to the establishment of learning networks on European level</li>
                            <li>capacity building activities, discussions on EU contribution to local policies and initiatives as well as ways and tools for their improvement contribute to fostering
                                European citizenship and improving conditions and democratic participation at Union level.</li>
                            <li>to launch platforms for local dialogue between LAs, citizens and stakeholder.</li>
                            <li>to raise awareness on EU policies in relation to those societal challenges as well as on mechanisms for addressing them both at local and European level.</li>
                            <li>to create opportunities for learning and sharing new solutions: particularly ICT
                                technologies can be employed providing new tools for citizen participation and initiative.
                            </li>
                        </ul>
                        <br/>
                        <div className = "font-bold text-lg">International events, run by the 10 European partners, deal with:</div>
                        <ul className = "list-inside list-disc ml-4">
                            <li>Open-government: principles, methods and ICT open source tools&#34; focused on ICT tools for open government</li>
                            <li>Collaborative public services&#34; focuses in particular on the building and managing of public co-working spaces and co-housing</li>
                            <li>Innovative and sustainable financial instruments for local governments&#34; focuses on EU rules and mechanisms and on new sustainable financial instruments for PAs in response to the economic crisis</li>
                            <li>Participation &amp; Collaborative values as a way to innovation and local development&#34; focuses on collaborative values and rules to boost solidarity and innovation in local communities and on active citizenship (best practices, tools, methods and EU funds to sustain the participative process</li>
                        </ul>
                        <br/>
                        <div className = "font-bold text-lg">At local level, there are organised three paths on:</div>
                        <ul className = "list-inside list-decimal ml-4">
                            <li>How ICT could enforce Open-government?</li>
                            <li>Collaborative public services</li>
                            <li>Local innovative financial instruments</li>
                        </ul>
                    </div>
                )}
                {title === "SMART PARTICIPATION" && (
                    <div>
                       <div className = "font-bold text-lg">General Objective</div>
                       <p>
                        To support a dialogue between young people and policy makers on issues
                        of digital skills, entrepreneurship and innovative ideas.
                       </p>
                       <br/> 
                       <div className = "font-bold text-lg">Specific Objectives</div>
                       <ul className = "list-inside list-disc ml-4">
                        <li>to compare idea, content of young people with those of political decision-makers in
                            relation to digital skills, to the use of smart phone or other devices in the educational field and beyond</li>
                        <li>active involvement of the young people in the decision making process</li>
                        <li>facilitate the improvement of local/regional/European laws on smart phone use as an
                            educational tool by direct involvement of young people in decisions that touch them directly
                        </li>
                        </ul>
                        <br/>
                        <div className = "font-bold text-lg">Main Activities: 4 transnational meetings structured on:</div>
                        <ul className = "list-inside list-disc ml-4">
                            <li>smart phone and participation</li>
                            <li>smart phone and creativity</li>
                            <li>smart phone and inclusion</li>
                            <li>smart phone and digital skills</li>
                        </ul>
                    </div>
                )
                }
                {title === "FEMME" && (
                    <div>
                        <div className = "font-bold text-lg">Target Group</div>
                        <p>
                            In FEMME project we find a priority to involve <b>incubators/trainers/adult educators</b> on 
                            entrepreneurship to extend their competences for <b>Mumpreneurship</b> and provide a better
                            and suitable service for Mumpreneurs leading them to create jobs on a start-up environment.
                        </p>
                        <br/>
                        <div className = "font-bold text-lg">Outcomes</div>
                        <ul className = "list-inside list-disc ml-4">
                            <li>Report on the state-of-the-art of European Mumpreneurship</li>
                            <li>Guide on European best practices on Mumpreneurship</li>
                            <li>Guide with experiences/recommendations for Trainers/Adult Educators on Mumpreneurship initiatives</li>
                            <li>Guide for Mumpreneurs</li>
                            <li>Leaflets, Web and facebook page</li>
                        </ul>
                    </div>
                    )
                }
                {title === "CyberSeniors" && (
                    <div>
                        <div className = "font-lora font-bold text-lg">Activities</div>
                        <br/>
                        <div>
                            <h1 className = "font-lora font-bold">1st TPM | online | 18.01.2021</h1>
                            <p><b>Topics:</b> presentation of the project overviews and IOS; communication, dissemination strategies; project documents</p>
                            <img 
                                src={require("../images/cyberseniors/1st-tpm-1.jpg")}
                                alt={"Group of people having a meeting online on Google Meet"}
                            />
                        </div>
                        <br/>
                        <div>
                            <h1 className = "font-lora font-bold">2nd TPM | Vienna, Austria | 14-15.12.2021</h1>
                            <p><b>Topics:</b> status of IO1-works performed by each project partner; preparation of the LTTA-Madrid; intermediary report topics and details.</p>
                            <img 
                                src={require("../images/cyberseniors/2nd-tpm-1.jpg")}
                                alt={"Group of people having a meeting in an office"}
                            />
                        </div>
                        
                    </div>
                )}
                {activ && activ.length > 0 && (
                    <div>
                        <div className = "font-lora font-bold text-lg">Activities</div>
                        <ul className = "list-inside list-disc ml-4">
                            {activ.map((activity) => (
                                <li key = {activity}>
                                    {activity}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        <br/>
        </div>
    )
}
