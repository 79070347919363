import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"
import HeaderProjects from "../components/header-projects"


export default function ProjectPage({ data }) {
    const { title, fullTitle, number, type, aims, activ, partners } = data.projectsYaml;
    return(
        <Layout>
            <SEO title={title}/>
            <div className="flex flex-row">
                <HeaderProjects/>
            </div>
            <br/>
            <Project  
                title = {title} 
                fullTitle = {fullTitle} 
                type = {type} 
                number = {number} 
                partners = {partners}
                aims = {aims} 
                activ = {activ}
            />
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        projectsYaml(slug: {eq: $slug}) {
        activ
        aims
        fullTitle
        number
        partners
        slug
        title
        type
        }
    }
`;