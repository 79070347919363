import React from "react"
import {projectLinks} from "../data";

export default function HeaderProjects() {
    return(
        <div>{
            projectLinks.map((projectLink) => (
                <div className="font-montserrat text-xl hover:text-red-dark">
                    <a href={projectLink.route}>{projectLink.title}</a>
                </div>
            ))
        }
        </div>
    )
}